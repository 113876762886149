import styled from 'styled-components'

export const Container = styled.article`
  flex: 1;
  padding: 1.6rem 3.2rem 0.8rem;
  display: flex;
  overflow: auto;
  gap: 3.2rem;

  @media (max-width: 1248px) {
    flex-direction: column;
  }

  @media (max-width: 460px) {
    padding: 1.6rem 1.6rem;
  }
`
