import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { v4 as uuid } from 'uuid'

import { Prohibit } from '@phosphor-icons/react'

import { type ICategory } from '../../../@types/ICategory'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { api } from '../../../services/api'
import { getRecommendedCategories } from '../../../services/utils/getRecommendedCategories'
import { CategoryIcon } from '../../CategoryIcon'
import { ListCategoriesModal } from '../../Modals/ListCategoriesModal'
import { Container, SelectedCategoryButton } from './styles'

interface IOwnProps {
  label: string
  name: string
  type?: string | null
  withRecommendations?: boolean
}

type ICategorySelectProps = IOwnProps &
  Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'type'>

export const CategorySelect: React.FC<ICategorySelectProps> = ({
  label,
  placeholder,
  name,
  required,
  type,
  withRecommendations,
  ...props
}) => {
  const uniqueId = useRef<string | null>(null)
  const { register, setValue, getValues } = useFormContext()
  const [selected, setSelected] = useState<ICategory | null>(null)
  const [userCategories, setUserCategories] = useState<ICategory[]>([])
  const { addModal, removeModal } = useModal()
  const { changes } = useChanges()
  const recommendedCategories = getRecommendedCategories(userCategories)

  const handleSelectCategory = (category: ICategory | null) => {
    setSelected(category)
    removeModal(`list-categories-select-modal-${uniqueId.current ?? ''}`)
  }

  const handleOpenListCategoriesModal = () => {
    addModal({
      id: `list-categories-select-modal-${uniqueId.current ?? ''}`,
      content: (
        <ListCategoriesModal
          preloadedCategories={userCategories}
          onSelectCategory={handleSelectCategory}
          showRecommendations={withRecommendations}
          type={type}
        />
      ),
    })
  }

  useEffect(() => {
    async function getUserCategories() {
      const { data } = await api.get<ICategory[]>('/categories')

      setUserCategories(data)
    }

    getUserCategories().catch(console.error)
  }, [changes.categories])

  useEffect(() => {
    if (selected) {
      if (recommendedCategories.some((rec) => rec.id === selected?.id)) {
        setValue(name, JSON.stringify({ ...selected, id: undefined }))
      } else {
        setValue(name, JSON.stringify(selected))
      }
    } else {
      setValue(name, '')
    }
  }, [getValues, name, recommendedCategories, selected, setValue])

  useEffect(() => {
    if (!uniqueId.current) {
      uniqueId.current = uuid()
    }
  }, [])

  return (
    <Container>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <SelectedCategoryButton
        className={!selected ? 'none-selected' : ''}
        type="button"
        onClick={handleOpenListCategoriesModal}
      >
        {!selected && (
          <>
            <Prohibit size={'2.4rem'} />
            <p>{placeholder ?? 'Nenhuma categoria selecionada'}</p>
          </>
        )}
        {selected && (
          <>
            <CategoryIcon category={selected} />
            <p>{selected.title}</p>
          </>
        )}
      </SelectedCategoryButton>
      <input type="hidden" required={required} {...props} {...register(name)} />
    </Container>
  )
}
