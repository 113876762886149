import { Trash } from '@phosphor-icons/react'

import { type ICategory } from '../../../../@types/ICategory'
import { CategoryIcon } from '../../../../components/CategoryIcon'
import { ActionButton, Actions, Container } from './styles'

interface ICategoryProps {
  data: ICategory
  onDelete: () => void
}

export const Category: React.FC<ICategoryProps> = ({ data, onDelete }) => {
  return (
    <Container>
      <div className="icon">
        <CategoryIcon category={data} />
      </div>
      <div className="category-info">
        <h3>{data.title}</h3>
        <span>{data.description}</span>
      </div>

      <Actions>
        <ActionButton color="#e01e37" onClick={onDelete}>
          <Trash size={'2.4rem'} />
        </ActionButton>
      </Actions>
    </Container>
  )
}
