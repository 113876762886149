import { useState, useEffect } from 'react'

import { type ITransaction } from '../../../@types/ITransaction'
import { Button } from '../../../components/Button'
import { AddTransactionModal } from '../../../components/Modals/AddTransactionModal'
import { Transaction } from '../../../components/Transaction'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useModal } from '../../../hooks/ModalContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { Container, Header } from './styles'

export const MainPanel: React.FC = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const { changes, handleChange } = useChanges()
  const { addModal, removeModal } = useModal()
  const { currentWallet } = useWallet()

  const handleCloseAddTransactionModal = () => {
    removeModal('modal-add-transaction')
  }
  const handleOpenAddTransactionModal = () => {
    addModal({
      id: 'modal-add-transaction',
      content: <AddTransactionModal onClose={handleCloseAddTransactionModal} />,
    })
  }

  const handleDelete = async (id: string) => {
    if (!currentWallet?.id) return
    await api.delete(`/wallet/${currentWallet.id}/transactions/${id}`)
    handleChange('transactions')
  }

  useEffect(() => {
    async function getTransactions() {
      if (!currentWallet?.id) return
      const response = await api.get<ITransaction[]>(
        `/wallet/${currentWallet.id}/transactions`,
        {
          params: {
            wallet_id: currentWallet?.id ?? undefined,
          },
        },
      )
      setTransactions(response.data)
    }

    getTransactions().catch(console.error)
  }, [changes.transactions, currentWallet?.id])

  return (
    <Container>
      <Header>
        <h3>Movimentações</h3>

        <Button model="small" onClick={handleOpenAddTransactionModal}>
          Adicionar Movimentação
        </Button>
      </Header>

      <div className="transaction-list">
        {transactions.map((transaction) => (
          <Transaction
            key={transaction.id}
            data={transaction}
            // onEdit={() => {
            //   handleEdit(transaction.id)
            // }}
            onDelete={() => {
              handleDelete(transaction.id).catch(console.error)
            }}
          />
        ))}

        {transactions.length === 0 && (
          <span className="empty">
            Está muito limpo por aqui, adoraria ver os meus gastos assim também!
          </span>
        )}
      </div>
    </Container>
  )
}
