import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  Crown,
  CurrencyDollar,
  House,
  List,
  Stack,
  X,
} from '@phosphor-icons/react'

import { Adsense } from '../../../../components/Adsense'
import { useAuth } from '../../../../hooks/AuthContextProvider'
import { usePlan } from '../../../../hooks/PlanContextProvider'
import {
  Container,
  Logo,
  ToggleMenuButton,
  MenuHeader,
  MenuItem,
  MenuList,
} from './styles'

export const Menu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(() => window.innerWidth > 730)
  const { isLogged, user } = useAuth()
  const { pathname } = useLocation()
  const { useAd } = usePlan()

  const year = `2023${
    new Date().getFullYear() !== 2023 ? ` - ${new Date().getFullYear()}` : ''
  }`

  useEffect(() => {
    const isMobile = window.innerWidth <= 730
    if (isMobile) {
      setIsMenuOpen(false)
    }
  }, [pathname])

  useEffect(() => {
    function onResize() {
      const isMobile = window.innerWidth <= 730
      if (!isMobile) {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <Container className={isMenuOpen ? 'open' : ''}>
      <MenuHeader className={isMenuOpen ? 'open' : ''}>
        <ToggleMenuButton
          className={isMenuOpen ? 'open' : ''}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}
        >
          {isMenuOpen ? <X size={'3.2rem'} /> : <List size={'3.2rem'} />}
        </ToggleMenuButton>

        <Logo to="/" className={isMenuOpen ? 'open' : ''}>
          <img
            src="/complete-logo-white.png"
            alt="Logo branca completa do Okaneko"
          />
        </Logo>
      </MenuHeader>
      <MenuList>
        {isLogged && user?.role === 'ADMIN' && (
          <MenuItem className={pathname === '/admin' ? 'active' : ''}>
            <Link to="/admin">
              <div className="icon">
                <Crown size={'2.4rem'} />
              </div>
              Administrador
            </Link>
          </MenuItem>
        )}
        <MenuItem className={pathname === '/' ? 'active' : ''}>
          <Link to="/">
            <div className="icon">
              <House size={'2.4rem'} />
            </div>
            Visão geral
          </Link>
        </MenuItem>
        <MenuItem className={pathname === '/transactions' ? 'active' : ''}>
          <Link to="/transactions">
            <div className="icon">
              <CurrencyDollar size={'2.4rem'} />
            </div>
            Movimentações
          </Link>
        </MenuItem>
        <MenuItem className={pathname === '/categories' ? 'active' : ''}>
          <Link to="/categories">
            <div className="icon">
              <Stack size={'2.4rem'} />
            </div>
            Categorias
          </Link>
        </MenuItem>
        {/* <MenuItem className={pathname === '/dashboards' ? 'active' : ''}>
          <Link to="/dashboards">
            <div className="icon">
              <SquaresFour size={'2.4rem'} />
            </div>
            Painéis
          </Link>
        </MenuItem> */}
      </MenuList>

      {useAd && (
        <>
          <span className="spacer"></span>
          <Adsense
            adSlot="6037858024"
            format="fixed"
            size={{ x: '22.4rem', y: '22.4rem' }}
          />
          <span className="spacer"></span>
          <Adsense
            adSlot="6037858024"
            format="fixed"
            size={{ x: '22.4rem', y: '22.4rem' }}
          />
        </>
      )}
      <span className="spacer"></span>
      <span className="copyright">
        <span>Okaneko</span>
        <span>&copy;</span>
        <span className="year">{year}</span>
      </span>
    </Container>
  )
}
