import styled from 'styled-components'

export const Container = styled.section`
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media (max-width: 1248px) {
    flex: unset;
    padding: 0 1.4rem 0 2.4rem;
    height: fit-content;
  }

  @media (max-width: 730px) {
    padding: 0 0.8rem 0 0;
  }
`
