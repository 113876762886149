import styled from 'styled-components'

export const Container = styled.article`
  flex: 1;
  position: relative;
  display: flex;
  gap: 3.2rem;
  padding: 1.6rem 3.2rem 0.8rem;

  @media (max-width: 987px) {
    padding: 1.6rem 3.2rem 0.8rem;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  @media (max-width: 460px) {
    padding: 1.6rem;
  }
`
