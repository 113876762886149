import { FormProvider, useForm } from 'react-hook-form'

import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { formatMoney } from '../../../services/utils/inputs/formatMoney'
import { parseNumber } from '../../../services/utils/parseNumber'
import { Button } from '../../Button'
import { Input } from '../../Inputs/Input'
import { InputCheckbox } from '../../Inputs/InputCheckbox'
import { InputColor } from '../../Inputs/InputColor'
import { InputIcon } from '../../Inputs/InputIcon'
import { InputSelect } from '../../Inputs/InputSelect'
import { InputWithFormater } from '../../Inputs/InputWithFormater'
import { ModalHeader } from '../ModalHeader'
import { Container, Row } from './styles'
import { formSchema, type IFormData } from './validation'

interface IAddWalletModalProps {
  onClose: () => void
}

export const AddWalletModal: React.FC<IAddWalletModalProps> = ({ onClose }) => {
  const methods = useForm<IFormData>({
    resolver: formSchema,
    defaultValues: {
      color: '#FF4A8F',
      iconUrl: 'Money',
    },
  })
  const { addToast } = useToast()
  const { handleChange } = useChanges()
  const { wallets } = useWallet()
  const isCreditCard = methods.watch('isCreditCard')
  const color = methods.watch('color')

  const handleSubmit = async (data: IFormData) => {
    const body: {
      name: string
      color?: string
      iconUrl?: string
      isCreditCard?: boolean
      limit?: number
      dueDate?: number
      closeOnWalletId?: string
    } = { name: data.name }
    if (data.isCreditCard) {
      body.color = data.color
      body.iconUrl = data.iconUrl
      body.isCreditCard = data.isCreditCard
      body.limit = parseNumber(data.limit ?? '0')
      body.dueDate = Number(data.dueDate ?? '10')
      body.closeOnWalletId = data.closeOnWalletId
    }

    try {
      await api.post('/wallets', body)
      addToast({
        title: 'Sucesso!',
        description: 'Carteira adicionada com sucesso!',
        type: 'success',
        time: 5,
      })
      handleChange('wallets')

      onClose()
    } catch {}
  }

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(handleSubmit)}>
        <ModalHeader title="Adicionar Carteira" onClose={onClose} />
        <Row>
          <Input
            label="Nome"
            name="name"
            placeholder="O nome da sua carteira."
          />
          <InputColor label="Cor" name="color" />
          <InputIcon label="Ícone" name="iconUrl" color={color} />
        </Row>
        <InputCheckbox
          label="Cartão de crédito"
          name="isCreditCard"
          type="checkbox"
        />
        {isCreditCard && (
          <>
            <InputWithFormater
              name="limit"
              label="Limite"
              placeholder="Qual o valor?"
              align="right"
              formatter={formatMoney}
              required
            />
            <InputSelect
              label="Data de fechamento"
              name="dueDate"
              placeholder="Qual o dia de fechamento da sua fatura?"
              options={Array.from({ length: 31 }).map((_, day) => ({
                label: (day + 1).toString(),
                value: (day + 1).toString(),
              }))}
              required
            />
            <InputSelect
              label="Carteira padrão de pagamento"
              name="closeOnWalletId"
              placeholder="Escolha a carteira para pagamentos"
              options={wallets.map((wallet) => ({
                label: wallet.name,
                value: wallet.id,
              }))}
            />
          </>
        )}
        <Button type="submit">Adicionar Carteira</Button>
      </Container>
    </FormProvider>
  )
}
