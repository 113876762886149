import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { validate } from 'uuid'

import { X } from '@phosphor-icons/react'

import { type ICategory } from '../../../@types/ICategory'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { api } from '../../../services/api'
import { Button } from '../../Button'
import { CategorySelect } from '../../Inputs/CategorySelect'
import { Input } from '../../Inputs/Input'
import { InputColor } from '../../Inputs/InputColor'
import { InputIcon } from '../../Inputs/InputIcon'
import { InputSelect } from '../../Inputs/InputSelect'
import { CloseButton, Container, Header, Row } from './styles'

interface IAddCategoryModalProps {
  onClose: () => void
}

interface IFormData {
  title: string
  color: string
  iconUrl: string
  description?: string
  categoryId?: string
  type?: '' | 'INCOME' | 'OUTCOME'
}

export const AddCategoryModal: React.FC<IAddCategoryModalProps> = ({
  onClose,
}) => {
  const { handleChange } = useChanges()
  const [loadingAddCategory, setLoadingAddCategory] = useState(false)
  const { addToast } = useToast()
  const methods = useForm<IFormData>({
    defaultValues: {
      title: '',
      description: '',
      color: '#FF4A8F',
      iconUrl: 'Money',
      type: '',
      categoryId: '',
    },
  })

  const color = methods.watch('color')

  const submit = async (data: IFormData) => {
    setLoadingAddCategory(true)
    if (!validate(data.categoryId ?? '')) {
      try {
        if (!data.categoryId) {
          throw new Error('Sem categorias')
        }
        const body = JSON.parse(data.categoryId)
        if (body.id) {
          data.categoryId = body.id
        } else {
          const category = await api.post<ICategory>('/categories', body)
          handleChange('categories')
          data.categoryId = category.data.id
        }
      } catch (err) {
        data.categoryId = undefined
      }
    }

    try {
      if (!data.description) {
        data.description = undefined
      }
      if (!data.type) {
        data.type = undefined
      }
      await api.post<ICategory>('/categories', data)
      handleChange('categories')
      addToast({
        title: 'Sucesso!',
        description: 'A categoria foi inserida com sucesso!',
        type: 'success',
        time: 3,
      })
      setLoadingAddCategory(false)
      onClose()
    } catch {
      addToast({
        title: 'Oops...',
        description: 'Parece que houve um problema ao inserir a categoria...',
        type: 'error',
        time: 5,
      })
      setLoadingAddCategory(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(submit)}>
        <Header>
          <h3>Adicionar categoria</h3>
          <CloseButton onClick={onClose}>
            <X size={'3.2rem'} />
          </CloseButton>
        </Header>
        <Row>
          <InputColor name="color" label="Cor" />
          <InputIcon name="iconUrl" label="Ícone" color={color} />
          <CategorySelect
            name="categoryId"
            label="Subcategoria dê"
            withRecommendations
          />
        </Row>
        <Row>
          <Input
            name="title"
            label="Título"
            placeholder="Dê um nome pra categoria!"
            required
          />
          <InputSelect
            name="type"
            label="Tipo"
            placeholder="É uma categoria para entradas ou saídas?"
            options={[
              { label: 'Entradas e Saídas', value: '' },
              { label: 'Saídas', value: 'OUTCOME' },
              { label: 'Entradas', value: 'INCOME' },
            ]}
          />
        </Row>
        <Input name="description" label="Descrição" placeholder="Descreva!" />
        <Button type="submit" loading={loadingAddCategory}>
          Criar Categoria
        </Button>
      </Container>
    </FormProvider>
  )
}
