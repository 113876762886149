import styled from 'styled-components'

export const Container = styled.section`
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .calendar-row {
      display: flex;
      justify-content: ${(props) =>
        props.theme.useAd ? 'space-between' : 'center'};
      gap: 0.8rem;
    }
  }

  .chart {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;
  }

  @media (max-width: 1248px) {
    flex: unset;
    height: fit-content;
    padding: 1.6rem 1.6rem 0 1.6rem;
    overflow: unset;
  }

  @media (max-width: 730px) {
    padding: 1.6rem 0.8rem 0 0;
  }
`
