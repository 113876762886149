import styled from 'styled-components'

export const Container = styled.form`
  background-color: ${(props) => props.theme.colors.backgroundDarker};
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 50rem;
  padding: 1.6rem;
  border-radius: 0.8rem;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const Row = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CloseButton = styled.button`
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.backgroundDarker};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundBlack};
  }
`
