import styled from 'styled-components'

export const Container = styled.article`
  padding: 1.6rem;
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 1.6rem;
  gap: 1.6rem;

  @media (max-width: 460px) {
    flex-direction: column;
    gap: 0.8rem;
    min-height: auto;
  }
`

interface ICategoryProps {
  color?: string | null
}

export const Category = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})<ICategoryProps>`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 0.8rem;
  ${(props) => props.theme.colors.textDarker}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgroundDarker};
`

export const Title = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h4 {
    flex: 1;
    font-size: 2rem;
    max-height: 4.8rem;
    min-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    flex: 1;
    font-size: 1.2rem;
    opacity: 0.4;
  }
`

export const Info = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 0.8rem;

  .date {
    font-size: 1.4rem;
    opacity: 0.4;
  }
  .money {
    font-size: 1.8rem;
    font-weight: 700;
    color: #1dd3b0;

    &.negative {
      color: #e01e37;
    }
  }
`

export const Actions = styled.section`
  border-left: 0.1rem solid #fff2;
  height: 100%;
  display: flex;
  gap: 1.6rem;
  padding-left: 1.6rem;

  @media (max-width: 460px) {
    border: none;
    padding: 0;
  }
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 0.8rem;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s;

  &:hover {
    opacity: 1;
  }
`

interface IResponsiveLine {
  reverse?: boolean
}

export const ResponsiveLine = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'reverse',
})<IResponsiveLine>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  gap: 1.6rem;
  overflow: hidden;
  min-height: 4.8rem;
  max-height: 4.8rem;

  @media (max-width: 460px) {
    width: 100%;
  }
`
