import styled, { css } from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 2rem;
    font-weight: 700;
  }
`

export const Row = styled.section`
  display: flex;
  gap: 1.6rem;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

interface IBoxProps {
  type?: 'income' | 'outcome' | 'balance'
}

export const Box = styled.section.withConfig({
  shouldForwardProp: (prop) => prop !== 'type',
})<IBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.textLighter};
  position: relative;
  min-height: 16rem;
  padding: 3.2rem;
  background: linear-gradient(90deg, #000000, #2e2e2e);
  border-radius: 1.6rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);

  h4 {
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;
    font-weight: 400;
  }

  .row {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    flex-wrap: wrap;
  }

  .money-item {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    position: relative;
    height: 7.4rem;

    .money {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-size: 4rem;
      font-weight: 700;
      height: 4.9rem;
      min-width: 10rem;
      text-align: right;
      white-space: nowrap;

      &.negative {
        color: #e01e37;
      }

      @media (max-width: 400px) {
        font-size: 3.2rem;
      }
      @media (max-width: 320px) {
        font-size: 2.4rem;
      }
    }

    .info {
      font-size: 1.2rem;
      font-weight: 400;
      opacity: 0.7;
      height: 1.6rem;
      bottom: 0;
      right: 0;
      position: absolute;
    }

    &.small {
      .money {
        font-size: 1.8rem;
      }
    }
    &.normal {
      .money {
        font-size: 2.4rem;
      }
    }
    &.medium {
      .money {
        font-size: 3.2rem;

        @media (max-width: 320px) {
          font-size: 2.4rem;
        }
      }
    }

    & + .money-item {
      margin-left: 6.4rem;
    }

    & + .small {
      margin-left: 3.2rem;
    }
  }

  ${(props) =>
    props.type === 'income' &&
    css`
      background: linear-gradient(90deg, #086375, #1dd3b0);
    `}
  ${(props) =>
    props.type === 'outcome' &&
    css`
      background: linear-gradient(90deg, #880d1e, #e01e37);
    `}
`
