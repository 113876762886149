import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 90%;
  max-width: 50rem;
  padding: 3.2rem;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  border-radius: 1.6rem;
`

export const Row = styled.section`
  display: flex;
  gap: 0.8rem;
`
