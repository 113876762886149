import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { CaretDown } from '@phosphor-icons/react'

import { type ISelectOption } from '../../../@types/ISelectOption'
import { Container, SelectContainer, SelectOption } from './styles'

interface IOwnProps {
  label: string
  options: ISelectOption[]
  name: string
}

type IInputProps = IOwnProps & React.HTMLProps<HTMLInputElement>

export const InputSelect: React.FC<IInputProps> = ({
  label,
  options,
  required,
  name,
  placeholder,
  ...props
}) => {
  const { register, setValue, getValues } = useFormContext()
  const [selected, setSelected] = useState(getValues(name) || '')
  const [isOpen, setIsOpen] = useState(false)

  function selectOption(option: ISelectOption) {
    setSelected(option.value)
  }

  const selectedLabel =
    options.find((item) => item.value === selected)?.label ?? ''

  useEffect(() => {
    setValue(name, selected)
  }, [name, selected, setValue])

  return (
    <Container>
      <p>
        <strong>
          {label} {required && '*'}
        </strong>
      </p>
      <input
        readOnly
        value={selectedLabel}
        required={required}
        placeholder={placeholder}
        onFocus={() => {
          setIsOpen(true)
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
      />

      <input type="hidden" required={required} {...props} {...register(name)} />

      {isOpen && (
        <SelectContainer>
          {options.map((option) => (
            <SelectOption
              key={option.value}
              onMouseDown={() => {
                selectOption(option)
              }}
              type="button"
            >
              {option.label}
            </SelectOption>
          ))}
        </SelectContainer>
      )}

      <div className="icon">
        <CaretDown size={'3.2rem'} />
      </div>
    </Container>
  )
}
