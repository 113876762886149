import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { type ICategory } from '../../../@types/ICategory'
import { type ITransaction } from '../../../@types/ITransaction'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useToast } from '../../../hooks/ToastContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { formatMoney } from '../../../services/utils/inputs/formatMoney'
import { parseNumber } from '../../../services/utils/parseNumber'
import { Button } from '../../Button'
import { CategorySelect } from '../../Inputs/CategorySelect'
import { Input } from '../../Inputs/Input'
import { InputSelect } from '../../Inputs/InputSelect'
import { InputWithFormater } from '../../Inputs/InputWithFormater'
import { WalletSelect } from '../../Inputs/WalletSelect'
import { ModalHeader } from '../ModalHeader'
import { Container, Row } from './styles'

interface IFormData {
  wallet: string
  type: string
  value: string
  title: string
  description: string
  category: string
}
interface IAddTransactionModalProps {
  onClose: () => void
}

export const AddTransactionModal: React.FC<IAddTransactionModalProps> = ({
  onClose,
}) => {
  const methods = useForm<IFormData>({
    defaultValues: {
      type: 'OUTCOME',
      value: 'R$ 0,00',
    },
  })
  const { currentWallet } = useWallet()
  const { addToast } = useToast()
  const { handleChange } = useChanges()
  const type = methods.watch('type')

  const submit = async (data: IFormData) => {
    if (!currentWallet?.id) {
      return
    }

    const category = data.category ? JSON.parse(data.category) : null
    let categoryId = category?.id

    if (category?.title && !categoryId) {
      try {
        const categoryResponse = await api.post<ICategory>(
          '/categories',
          category,
        )

        categoryId = categoryResponse.data.id
      } catch {
        console.error('Error: Category not created')
      }
    }

    try {
      const formattedData = {
        type: data.type || 'OUTCOME',
        value: parseNumber(data.value),
        title: data.title,
        description: data.description || undefined,
        paidIn: new Date(),
        categoryId,
      }
      const walletId = data.wallet || currentWallet.id

      await api.post<ITransaction>(
        `/wallet/${walletId}/transactions`,
        formattedData,
      )
      handleChange('transactions')

      addToast({
        title: 'Sucesso!',
        description: 'A movimentação foi inserida com sucesso!',
        type: 'success',
        time: 3,
      })
      onClose()
    } catch {
      addToast({
        title: 'Oops...',
        description:
          'Parece que houve um problema ao inserir a movimentação...',
        type: 'error',
        time: 5,
      })
    }
  }

  useEffect(() => {
    methods.setFocus('value')
  }, [methods])

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(submit)}>
        <ModalHeader title="Adicionar movimentação" onClose={onClose} />
        <Row>
          <CategorySelect
            label="Categoria"
            name="category"
            type={type}
            withRecommendations
          />
          <WalletSelect label="Carteira" name="wallet" required />
        </Row>
        <Row>
          <InputWithFormater
            name="value"
            label="Valor"
            placeholder="Qual o valor?"
            align="right"
            formatter={formatMoney}
            className={type.toLowerCase()}
            required
          />
          <InputSelect
            name="type"
            label="Tipo da transação"
            options={[
              { value: 'OUTCOME', label: 'Saída' },
              { value: 'INCOME', label: 'Entrada' },
            ]}
            required
          />
        </Row>
        <Input name="title" label="Título" placeholder="Dê um nome!" required />
        <Input name="description" label="Descrição" placeholder="Descreva!" />
        <Button type="submit">Adicionar movimentação</Button>
      </Container>
    </FormProvider>
  )
}
