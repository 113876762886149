import { LateralPanel } from './LateralPanel'
import { MainPanel } from './MainPanel'
import { Container } from './styles'

export const Home: React.FC = () => {
  return (
    <Container>
      <MainPanel />
      <LateralPanel />
    </Container>
  )
}
